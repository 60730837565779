import React, {useContext, useEffect, useState, useTransition} from 'react';
import { Form, Button, DatePicker, Tag,  Input, TimePicker, Row, Col } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import './styles.scss'
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { useAllTransactions } from '../../Services/useAllTransactions';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español si lo necesitas
import { useSearchTransactions } from '../../Services/useSearchTransaction';
import { useWindowSize } from '../../hooks/useWindowSize';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { UserContext } from '../../Context/UserContext/UsersContext';

export interface NotificationFilters {
  colors: string[] | undefined,
  datePicker: Date[] | undefined,
  machines:  number[] | undefined,
  organizations: number[]| undefined,
}

export const TransactionsFilters: React.FC =()=>{

  const { 
    dateAndHours, 
    setTransactionsLoading, 
    transactionsLoading, 
    setDataGroupedOfTransactions,
    typeTransactions,
    setDataOfAllTransactions,
    setSearchTTransactions,
    dateAndHoursAllTransactions,
    setDateAndHours,
    setDateAndHoursAllTransactions
  } = useContext(TransactionsContext)

  const { 
    setLoadingBody, 
    loadingBody,
  } = useContext(BodyContext)

  const {subAgencydata} = useContext(UserContext)
  const [form_transactions] = Form.useForm();
  const [form_search_transactions] = Form.useForm();
  
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [formData, setFormData] = useState<string>('')

  const {filterTransactionsPerDay} = useTransactionsPerDAy()
  const {filterAllTransactions} = useAllTransactions()
  const {searchTransactionsServise} = useSearchTransactions()
  const { ancho } = useWindowSize()
  
  // useEffect(()=>{
  //   form_transactions.resetFields()
  // },[typeTransactions, subAgencydata])


  useEffect(()=>{
      if(dateAndHours && subAgencydata){
        setTransactionsLoading({
          ...transactionsLoading,
          getTransactionsPerDay: true              
        })            
          filterTransactionsPerDay() 
      }   
  },[dateAndHours, subAgencydata])

  useEffect(()=>{
    if(dateAndHoursAllTransactions){
        setLoadingBody({
            ...loadingBody,
            perDayReport: true
        })  
        filterAllTransactions()           
    }   
  },[dateAndHoursAllTransactions, subAgencydata])

  const handleTransactionsFilter = async(e: any) =>{
    typeTransactions === 'perDay' && setDataGroupedOfTransactions(undefined);
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: true              
    })
    let first_date = e.first_date.format('YYYY/M/DD')
    let first_hours = e.first_hours.format('HH:mm:ss')
    let second_date = e.second_date.format('YYYY/M/DD')
    let second_hours = e.second_hours.format('HH:mm:ss')
    
    if(typeTransactions === 'perDay'){
      setDataGroupedOfTransactions(undefined)
      setDateAndHours({
        firstDate: first_date,
        firstHours: first_hours,
        secondDate: second_date,
        secondHours: second_hours,
      })
    }else if(typeTransactions === 'allTransactions'){
      setDataOfAllTransactions(undefined)
      //await filterAllTransactions(first_date, first_hours, second_date, second_hours)
      setDateAndHoursAllTransactions({
        firstDate: first_date,
        firstHours: first_hours,
        secondDate: second_date,
        secondHours: second_hours,
      })
    }   
  }

  const disabledEndDate = (current: Dayjs | null) => {
    if(typeTransactions === 'allTransactions'){
      if (startDate && current) {
        const minDate = dayjs(startDate).subtract(0, 'days');
        const maxDate = dayjs(startDate).add(3, 'days');
        return (current && (current < minDate || current > maxDate));
      }
      return true;
    }else{
      return false
    }
  };

  const handleChangePicker = (date: Dayjs | null) => {
    setStartDate(date);
    form_transactions.setFieldsValue({
      second_date: undefined
    });
  };

  const handleSearch = async (event: any) =>{
    setDataOfAllTransactions(undefined)
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: true,
    }) 
    await searchTransactionsServise(formData)
  }

  const handleChangeSearch = (event: any)=>{
    event.preventDefault()
    const name = event.target.name 
    const value = event.target.value
    setFormData(value)
  };

  const handleReset = ()=>{
    setDataOfAllTransactions(undefined)
    setTransactionsLoading({
      ...transactionsLoading,
      getTransactionsPerDay: false,
    }) 
    setFormData('')
    setSearchTTransactions(undefined) 
    form_search_transactions.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

  useEffect(() => {
    if(typeTransactions === 'perDay' ){
      if (dateAndHours) {    
        let  first_date = dayjs(`${dateAndHours.firstDate}`)
        let  first_hours = dayjs(dateAndHours.firstHours, 'HH:mm:ss')
        let  second_date  = dayjs(`${dateAndHours.secondDate}` )
        let  second_hours = dayjs(dateAndHours.secondHours, 'HH:mm:ss')   
      
        if (first_date.isValid() && second_date.isValid() && first_hours.isValid() && second_hours.isValid()) {
          form_transactions.setFieldsValue({
            first_date: first_date,
            first_hours: first_hours,
            second_date: second_date,
            second_hours: second_hours
          });
        } else {
          console.error('Fecha inválida:');
        }
      }
    }else if(typeTransactions === 'allTransactions'){
      if (dateAndHoursAllTransactions) {    
        let  first_date = dayjs(`${dateAndHoursAllTransactions.firstDate}`)
        let  first_hours = dayjs(dateAndHoursAllTransactions.firstHours, 'HH:mm:ss')
        let  second_date  = dayjs(`${dateAndHoursAllTransactions.secondDate}` )
        let  second_hours = dayjs(dateAndHoursAllTransactions.secondHours, 'HH:mm:ss')   
      
        if (first_date.isValid() && second_date.isValid() && first_hours.isValid() && second_hours.isValid()) {
          form_transactions.setFieldsValue({
            first_date: first_date,
            first_hours: first_hours,
            second_date: second_date,
            second_hours: second_hours
          });
        } else {
          console.error('Fecha inválida:');
        }
      }else{
        form_transactions.resetFields()
      }
    }
     
    }, [typeTransactions, dateAndHours, dateAndHoursAllTransactions ]);


  return(
    <div className="transactionFilter_container">
       {ancho < 1300 && //TABLET
        <>
          <Form
            layout="vertical"
            id='form_transactions'
            form={form_transactions}
            onFinish={handleTransactionsFilter}  
          >
            <Row style={{width: ancho > 1100 ? '80%' : ' 100%', marginTop:'5px'}}>
              <Col span={5}>
                <Form.Item 
                label='Fecha desde'
                name='first_date'
                className="transactionFilter_datePicker"
                rules={[
                  {
                    required: true,
                    message: 'Seleccionar fecha',
                  },
                ]}>
                  <DatePicker 
                    onChange={handleChangePicker}
                    placeholder='Fecha'
                    //disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item 
                label='Hora desde'
                name='first_hours'          
                className="transactionFilter_datePicker"
                rules={[
                  {
                    required: true,
                    message: 'Seleccionar hora',
                  },
                ]}
                > 
                  <TimePicker  
                    placeholder='Hora'/>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item 
                name='second_date'
                label='Fecha hasta'
                className="transactionFilter_datePicker"
                rules={[
                  {
                    required: true,
                    message: 'Seleccionar fecha',
                  },
                ]}
                >
                  <DatePicker
                    placeholder='Fecha'
                    disabledDate={disabledEndDate}
                  // onChange={handleEndDateChange}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item 
                name='second_hours'
                label='Hora hasta'
                className="transactionFilter_datePicker"
                rules={[
                  {
                    required: true,
                    message: 'Seleccionar hora',
                  },
                ]}
                >
                  <TimePicker  placeholder='Hora' />
                </Form.Item>
              </Col>
              <Col span={4} style={{ display: 'flex', alignItems:'center'}}>
                  <Button
                    style={{marginTop: '5px'}}
                    form='form_transactions'
                    type="ghost"
                    className="transactionFilter_filtersBtn"
                    htmlType="submit"
                    //loading={loadingSearch}
                  >
                    Aplicar
                  </Button>
              </Col>
            </Row>
          </Form>
          { typeTransactions === 'allTransactions' && 
            <Form
              layout="vertical"
              form={form_search_transactions}
              style={{display:'flex'}}
            >     
              <Form.Item
                style={{color:'white'}}
                name="ticketsNumber"
              >
                <Input
                  style={{
                    backgroundColor: 'transparent', 
                    color: 'white', 
                    borderColor: 'white', 
                    width: '156px',
                    marginRight:'20px'
                  }}
                  type='number'
                  placeholder='N° transacción'
                  onChange={handleChangeSearch}
                  value={formData}
              />                
              </Form.Item >
            
              <Button
                style={{marginRight:'20px'}}
                type="ghost"
                className="transactionFilter_filtersBtn"
                htmlType="submit"
                //loading={transactionsLoading.getTransactionsPerDay}
                onClick={handleSearch}
              >
                Buscar
              </Button>
        
              <Button
                  type="ghost"
                  className="transactionFilter_filtersBtn"
                  onClick={handleReset}
                >
                  Cancelar
              </Button>   
            </Form>
          }
        </>
      }
      {ancho > 1300 &&
          <>
          <p><div className='transactionFilter_point'> </div> FILTROS</p>
          <Form
            layout="vertical"
            id='form_transactions'
            form={form_transactions}
            onFinish={handleTransactionsFilter}  
          >
            <Form.Item 
              label='Fecha desde'
              name='first_date'
              className="transactionFilter_datePicker"
              rules={[
                {
                  required: true,
                  message: 'Seleccionar fecha',
                },
              ]}>
                <DatePicker 
                  style={{ width: '100%'}}
                  onChange={handleChangePicker}
                  //disabledDate={disabledDate}
                />
            </Form.Item>
            <Form.Item 
              label='Hora desde'
              name='first_hours'          
              className="transactionFilter_datePicker"
              rules={[
                {
                  required: true,
                  message: 'Seleccionar hora',
                },
              ]}
              > 
                <TimePicker  
                  style={{ width: '100%'}}/>
            </Form.Item>
            <Form.Item 
              name='second_date'
              label='Fecha hasta'
              className="transactionFilter_datePicker"
              rules={[
                {
                  required: true,
                  message: 'Seleccionar fecha',
                },
              ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={disabledEndDate}
                 // onChange={handleEndDateChange}
                />
            </Form.Item>
            <Form.Item 
              name='second_hours'
              label='Hora hasta'
              className="transactionFilter_datePicker"
              rules={[
                {
                  required: true,
                  message: 'Seleccionar hora',
                },
              ]}
              >
                <TimePicker style={{ width: '100%'}} />
            </Form.Item>
            <Form.Item>
                <Button
                  form='form_transactions'
                  type="ghost"
                  className="transactionFilter_filtersBtn"
                  htmlType="submit"
                  //loading={loadingSearch}
                >
                  Aplicar
                </Button>
              </Form.Item>
          </Form>
          { typeTransactions === 'allTransactions' && 
            <Form
              layout="vertical"
              form={form_search_transactions}
            > 
              <p style={{marginTop:'20px'  }}>Buscar transacción</p>
              <Form.Item
                style={{color:'white'}}
                name="ticketsNumber"
                className="transactionFilter_selectColor">
                <Input
                  style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white'}}
                  type='number'
                  placeholder='N° transacción'
                  onChange={handleChangeSearch}
                  value={formData}
              />                
              </Form.Item >
              <Row  justify="center">
                <Col span={12}>
                  <Form.Item
                    className='transactionFilter_container-btn'          
                  >
                    <Button
                      type="ghost"
                      className="transactionFilter_filtersBtn"
                      htmlType="submit"
                      //loading={transactionsLoading.getTransactionsPerDay}
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                      type="ghost"
                      className="transactionFilter_filtersBtn"
                      onClick={handleReset}
                    >
                      Cancelar
                  </Button>
                </Col>
              </Row>
            </Form>
          }
        </>
      }
      
    </div>
  )
}

export default TransactionsFilters;

