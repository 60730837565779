import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Select } from 'antd';
import { useGetListSubAgencies, Record } from '../../Services/useGetListSubAgencies';
import './styles.scss'
import { BodyContext, Navbar as NavbarType } from '../../Context/BodyContext.tsx/BodyContext';
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { useListAgencies } from '../../Services/useListAgencies'; 
import { useGetlistsAgencies } from '../../Services/useGetlistsAgencies';
import { useAgencycurrencyList } from '../../Services/useAgencycurrencyList';
import { start } from 'repl';

interface NavbarProps {
    changeNavbar: NavbarType,
    setChangeNavbar: Function
}

export const Navbar: React.FC<NavbarProps> =({changeNavbar, setChangeNavbar})=>{
    const {
        caballoNegroToken,
        setListSubAgencies,
        listSubAgencies,
        setSubAgencyData,
        userData,
        subAgencydata,
        selectedAgency, 
        setSelectedAgency,
        setSelectedAgencyCurrency,     
    } = useContext(UserContext)

    const { 
        setNewDataTickets,
        setNewDataPerDay,
        setLayerBody,
        setTypeMovement,
        setSeeAllTickets,
        setDatesMovementsPerDay,      
        datesMovementsPerDay          
    } = useContext(BodyContext)

    const {
        setDataOfAllTransactions, 
        setDataGroupedOfTransactions,
        setDateAndHoursAllTransactions,
        dateAndHours,
        setDateAndHours,
        
    } = useContext(TransactionsContext)

    const {data: record} = useGetListSubAgencies(caballoNegroToken)
    const {data: listAgencies} = useGetlistsAgencies(caballoNegroToken)
    const {getListAgencias} = useListAgencies()
    const {getAgencyCurrencyList} = useAgencycurrencyList()
    const { Option } = Select;
    
    const [loadingSubAgencias, setLoadingSubAgencias] = useState<boolean>(false)
              
    useEffect(()=>{
        if(record && userData?.nivel_agencia == '0'){
            setListSubAgencies(record)            
        }
    },[record])

    //CUANDO CAMBIA SELECTOR SE SUBAGENCIA
    const handleChange = (event: string)=>{        
        const date = new Date();
        let currentMonth =  date.getMonth() +1 
        let year = date.getFullYear() 
        let selectSubAgenci 

        if(userData?.nivel_agencia === '3' && userData.por_moneda == 1){
            selectSubAgenci = listSubAgencies.find((x)=> x.id_moneda == event)
        }else{
            selectSubAgenci = listSubAgencies.find((x)=> x.id_agencia == event)
        }

        setSeeAllTickets(false)                 
        setNewDataPerDay(undefined)
        setDataGroupedOfTransactions(undefined)
        setNewDataTickets(undefined); //limpio datos de 'Movimientos' 'tickets
        setDataOfAllTransactions(undefined) //limpio datos de 'Transacciones' 'transacciones
       // setDateAndHoursAllTransactions(undefined)
        setSubAgencyData(selectSubAgenci)
        setNewDataPerDay(undefined)
        setDataGroupedOfTransactions(undefined)
        setNewDataTickets(undefined); //limpio datos de 'Movimientos' 'tickets

        if(!datesMovementsPerDay){
            let perDay_StartDate= `${year}/${currentMonth}/01`
            let perDay_endDate = currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`
            setDatesMovementsPerDay({
                startDate: perDay_StartDate,
                endDate: perDay_endDate
            }) 
        }                 

        if(!dateAndHours){
            let first_date =`${year}/${currentMonth}/01`
            let first_hours = '00:00:00'
            let second_date = currentMonth == 12 ? `${year+1}/01/01` : `${year}/${currentMonth +1}/01`
            let second_hours = '00:00:00'
            setDateAndHours({
                firstDate: first_date,
                firstHours: first_hours,
                secondDate: second_date,
                secondHours: second_hours,
              })
        }        
       
        setDataOfAllTransactions(undefined) //limpio datos de 'Transacciones' 'transacciones
        //setDateAndHoursAllTransactions(undefined)                                 
    }

    const callback = () =>{
        setLoadingSubAgencias(false)
    }

    const handleChangeAgencie = (id: string) =>{
        setChangeNavbar('movements')
        setLayerBody('movements')
        setTypeMovement('per_day')     
        setDateAndHoursAllTransactions(undefined) 
        setDatesMovementsPerDay(undefined)
        setDateAndHours(undefined)
        

        setLoadingSubAgencias(true)
        setSubAgencyData(undefined)
        const selectedAgency = listAgencies?.filter((x)=> x.id_agencia == id)[0]
        setSelectedAgency(selectedAgency)
        setSelectedAgencyCurrency(undefined)
        if(userData?.nivel_agencia === '3' && userData.por_moneda == 1){ //14477 id Betaron
            getAgencyCurrencyList(id, callback) 
        }else{
            getListAgencias(id, callback)              
        }                    
    }

    const Selects: React.FC = () =>{
        switch(userData?.nivel_agencia){
            case '0':
                return <>
                    {record && record.length > 0 ?

                        <Select
                            showArrow
                            style={{ width: '200px' }}
                            onChange={(e)=>handleChange(e)}
                            placeholder="Subagencia"
                            defaultValue= {subAgencydata?.descripcion}
                        >
                            { record.map((agency)=> (
                                <Option value={agency.id_agencia} key={agency.descripcion}>
                                    {agency.descripcion}
                                </Option>
                            ))}
                        </Select>
                        :  userData?.agencia_nombre 
                            ? <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                            :<div></div>
                }
                </>               
            case '1':
                return <></>;
                
            case '3':
                return <>
                    {   listAgencies && listAgencies.length > 0 ?
                            <Select
                                showArrow
                                style={{ width: '200px' }}
                                onChange={(e)=>handleChangeAgencie(e)}
                                placeholder="Agencia"
                                defaultValue={selectedAgency?.descripcion}
                            >
                                { listAgencies.map((agency: Record)=> (
                                    <Option value={agency.id_agencia } key={agency.descripcion}>
                                        {agency.descripcion}
                                    </Option>
                                ))}
                            </Select>
                            :  userData?.agencia_nombre 
                                ? <div><SpinerHorizontal info={'Cargando agencias'} fontSize='12px'/></div>
                                :<div></div>
                    }
                    {
                        listSubAgencies && listSubAgencies.length > 0 && !loadingSubAgencias &&                   
                            <Select
                                showArrow
                                style={{ width: '200px', marginLeft: '10px' }}
                                onChange={handleChange}
                                placeholder={selectedAgency && selectedAgency.id_agencia ==='14477' ? 'Monedas' :'Subagencias'}
                                defaultValue= {subAgencydata?.descripcion}
                            >
                                { listSubAgencies.map((agency: Record)=> (
                                    <Option value={agency.id_moneda ? agency.id_moneda : agency.id_agencia} key={agency.descripcion}>
                                        {agency.descripcion}
                                    </Option>
                                ))}
                            </Select>                                                                            
                    }
                    {
                        loadingSubAgencias && <div><SpinerHorizontal info={selectedAgency && selectedAgency.id_agencia ==='14477' ? 'Cargando monedas' :'Cargando subagencias'} fontSize='12px'/></div>
                    }
                </>;
            default:
                return <></>;           
        }
    }

    return(
        <div className="navbar_container">
            <div className="navbar_row">
            {
                userData && userData.id_agencia
                ? <>
                    <p>{userData?.agencia_nombre}</p>
                        <Selects/> 
                </>
                : <SpinerHorizontal info={'Cargando'} fontSize='12px'/>
            }
                
            </div>
        </div>
    )
}

export default Navbar;