import { Col, Row } from "antd";
import React, { useCallback, useContext } from "react";
import { TransactionsContext } from "../../Context/TransactionsContext.tsx/TransactionsContext";
import { TransactionsPerDay } from "../../Services/useTransactionsPerDay";
import { roeSecond, style } from "./styles";
import { AllTransactions } from "../../Services/useAllTransactions";

interface TotalBalance_transactions{
    moneda: string,
    startDateTransactions: string,
    endDateTransactions: string,
    type: 'perDay' | 'allTransactions'
}
export const TotalBalance_transactions: React.FC<TotalBalance_transactions> =({
    moneda,
    startDateTransactions,
    endDateTransactions,
    type
})=>{

    const {
        dataGroupedOfTransactions,
        dataOfAllTransactions
    } = useContext(TransactionsContext)

   
    let partes = startDateTransactions.split(" ");
    let parte2 = endDateTransactions.split(" ");

    
    const totalBalance = useCallback (()=>{
        let totalCredit = 0;
        let totalDebit = 0;
        let totals = 0;
        let totalTickets = 0;

        let data = type ==='perDay' ? dataGroupedOfTransactions : dataOfAllTransactions
        if(type ==='perDay'){
            dataGroupedOfTransactions?.map((item: TransactionsPerDay ) =>{
                totalCredit = totalCredit + parseFloat( item.credit_total);
                totalDebit = totalDebit + parseFloat(item.debe_total);
                totals = totals + item.saldo;

                totalTickets = item.cantidad_tickets ? totalTickets + Number(item.cantidad_tickets) : 0;
            })  
        }else{
            dataOfAllTransactions?.map((item: AllTransactions ) =>{
                totalCredit = totalCredit + parseFloat( item.credit.toString());
                totalDebit = totalDebit + parseFloat(item.debit);
                totals = totals + Number(item.monto);
            }) 
        }


        let totalCreditCalculated = Intl.NumberFormat("de-DE").format(totalCredit) + ' ' + moneda
        let totalDebitCalculated = Intl.NumberFormat("de-DE").format(totalDebit) + ' ' + moneda
        let totalsCalculated = Intl.NumberFormat("de-DE").format(totalDebit-totalCredit) + ' ' + moneda//Intl.NumberFormat("de-DE").format(totals) + ' ' + moneda
        let totalTicketsCalculated = totalTickets

        return {
            totalCreditCalculated,
            totalDebitCalculated,
            totalsCalculated,
            totalTicketsCalculated
        }

    }, [dataGroupedOfTransactions, dataOfAllTransactions])


    return( totalBalance &&
        <>
            <div style={{
                background: 'white', 
                padding:'15px', 
                borderRadius: '5px',
                marginBottom: ' 10px'}}>
                <Row style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px',  border: '1px solid gray'}}>
                    <Col style={style} span={12}>Fecha desde</Col>
                    <Col style={style} span={12}>Fecha hasta</Col>
                </Row>
                <Row style={{ 
                    borderBottomLeftRadius:'5px', 
                    borderBottomRightRadius:'5px', 
                    borderBottom: '1px solid gray',
                    borderLeft: '1px solid gray',
                    borderRight: '1px solid gray',
                }}>
                    <Col style={roeSecond} span={12}>
                        <div style={{display: 'flex', flexDirection:'column', justifyContent:' center', alignItems: 'center'}}>
                           <p style={{margin: '0px', padding:'0px'}}>{partes[0]}</p>
                           <p style={{margin: '0px', padding:'0px'}}> {partes[1]}</p>
                          
                        </div>                      
                    </Col>
                    <Col style={roeSecond} span={12}>
                        <div style={{display: 'flex', flexDirection:'column', justifyContent:' center', alignItems: 'center'}}>
                           <p style={{margin: '0px', padding:'0px'}}>{parte2[0]}</p>
                           <p style={{margin: '0px', padding:'0px'}}> {parte2[1]}</p>                          
                        </div>  
                    </Col>
                </Row>
                <Row style={{margin:'8px 0px 0px 8px'}}>            
                    <Col span={10}> 
                        {type === 'perDay' && <p style={{color:'gray', fontSize:'13px'}}>Total tickets: </p>}
                        <p style={{color:'gray', fontSize:'13px'}}>Total credit:</p>
                        <p style={{color:'gray', fontSize:'13px'}}>Total debito: </p>
                        <p style={{color:'gray', fontSize:'13px'}}>Saldo total: </p>
                    </Col>
                    <Col  span={14}>
                        { type === 'perDay' &&<p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalTicketsCalculated }</p>}
                        <p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalCreditCalculated}</p>
                        <p style={{color:'black', fontSize:'13px'}}> {totalBalance().totalDebitCalculated}</p>
                        <p style={{color:'orange', fontSize:'13px'}}> {totalBalance().totalsCalculated}</p>     
                    </Col>
                </Row>
            </div>
        </>
    )
}