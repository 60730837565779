import { Col, Row, Tooltip } from "antd";
import React, { useCallback, useContext } from "react";
import { TransactionsContext } from "../../Context/TransactionsContext.tsx/TransactionsContext";
import { TransactionsPerDay } from "../../Services/useTransactionsPerDay";
import { roeSecond, style, styleBalance } from "./styles";
import { AllTransactions } from "../../Services/useAllTransactions";

interface TotalBalanceTransactions{
    moneda: string,
    startDateTransactions: string,
    endDateTransactions: string,
    type: 'perDay' | 'allTransactions'
}
export const TotalBalanceOfTransactions: React.FC<TotalBalanceTransactions> =({
    moneda,
    startDateTransactions,
    endDateTransactions,
    type
})=>{

    const {
        dataGroupedOfTransactions,
        dataOfAllTransactions
    } = useContext(TransactionsContext)
    
    const totalBalance = useCallback (()=>{
        let totalCredit = 0;
        let totalDebit = 0;
        let totals = 0;
        let totalTickets = 0;

        let data = type ==='perDay' ? dataGroupedOfTransactions : dataOfAllTransactions
        if(type ==='perDay'){
            dataGroupedOfTransactions?.map((item: TransactionsPerDay ) =>{
                totalCredit = totalCredit + parseFloat( item.credit_total);
                totalDebit = totalDebit + parseFloat(item.debe_total);
                totals = totals + item.saldo;

                totalTickets = item.cantidad_tickets ? totalTickets + Number(item.cantidad_tickets) : 0;
            })  
        }else{
            dataOfAllTransactions?.map((item: AllTransactions ) =>{
                totalCredit = totalCredit + parseFloat( item.credit.toString());
                totalDebit = totalDebit + parseFloat(item.debit);
                totals = totals + Number(item.monto);
            }) 
        }


        let totalCreditCalculated = Intl.NumberFormat("de-DE").format(totalCredit) + ' ' + moneda
        let totalDebitCalculated = Intl.NumberFormat("de-DE").format(totalDebit) + ' ' + moneda
        let totalsCalculated = Intl.NumberFormat("de-DE").format(totalDebit-totalCredit) + ' ' + moneda
        let totalTicketsCalculated = totalTickets

        return {
            totalCreditCalculated,
            totalDebitCalculated,
            totalsCalculated,
            totalTicketsCalculated
        }

    }, [dataGroupedOfTransactions, dataOfAllTransactions])

    // const calculateNumberOfTickets = useCallback(() =>{
    //     let numeberOfTickets: number = 0
    //     newDataPerDay?.map((item)=>{
    //         numeberOfTickets = numeberOfTickets + parseInt(item.cantidad_tickets)
    //     })
    //     return numeberOfTickets 
    // }, [newDataPerDay])

    
    return( totalBalance &&
        <>
            <Row >
                <Col style={style} span={type ==='perDay'? 4 : 5}>Fecha desde</Col>
                <Col style={style} span={type ==='perDay'? 4 : 5}>Fecha hasta</Col>
                {type ==='perDay' && <Col style={style} span={4}>Total tickets</Col>}
                <Col style={style} span={type ==='perDay'? 4 : 5}>Total credito</Col>
                <Col style={style} span={type ==='perDay'? 4 : 5}>Total debito</Col>
                <Col style={style} span={4}>Total Saldo</Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col style={roeSecond} span={type ==='perDay'? 4 : 5}>{startDateTransactions} {}</Col>
                <Col style={roeSecond} span={type ==='perDay'? 4 : 5}>{endDateTransactions}</Col>
                {type ==='perDay' && <Col style={roeSecond} span={4}>{totalBalance().totalTicketsCalculated}</Col>}
                <Col style={roeSecond} span={type ==='perDay'? 4 : 5}>{totalBalance().totalCreditCalculated}</Col>
                <Col style={roeSecond} span={type ==='perDay'? 4 : 5}>{totalBalance().totalDebitCalculated}</Col>
                <Col style={styleBalance} span={4}>{totalBalance().totalsCalculated}</Col>
                
            </Row>
        </>
    )
}