import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{
  API_END_POINT_REPORTE_TICKETS_FECHA,
  API_END_POINT_REPORTE_TICKETS_FECHA_MONEDA,
  API_END_POINT_REPORTE_TOKEN_RENEG
} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { BodyContext } from '../Context/BodyContext.tsx/BodyContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { useUser } from './useUser';

export const usePerDayReport =  ()=>{

  const { 
    caballoNegroToken,
    setCaballoNegroToken,
    setRegenerateToken,
    regenerateToken,
    tokenExpirationTime,
    tokeRefreshTime,
    setTokeRefreshTime,
    setTokenExpirationTime,
    setLoading,
    userData,
    subAgencydata,
    selectedAgency,
  } = useContext(UserContext)

  const {
    setNewDataPerDay,
    setLoadingBody,
    loadingBody,
    datesMovementsPerDay
  } = useContext(BodyContext)

  const {logOut}= useUser()
    
  const fetcher = async (    
    newToken?: string,
    
    ): Promise<void>  => {
      let url = API_END_POINT_REPORTE_TICKETS_FECHA
      const token = newToken ? newToken : caballoNegroToken
      let agency_id_acording_to_level: string | undefined
      let params = new URLSearchParams

      if(datesMovementsPerDay && userData){        
        switch(userData.nivel_agencia){
          case '1': //Uso el id directamente de la agencia que viene en el usuario
            agency_id_acording_to_level = userData?.id_agencia 
            break;
          case '0': //uso la subagencia elegida
            agency_id_acording_to_level = subAgencydata?.id_agencia
            break 
          case '3':           
            if(userData.por_moneda == 1){// si es 1 es true, uso el endpoitn por moneda
              agency_id_acording_to_level = selectedAgency?.id_agencia
              params.append('id_moneda', subAgencydata?.id_moneda ?? '');
              url = API_END_POINT_REPORTE_TICKETS_FECHA_MONEDA
            }else{
              agency_id_acording_to_level = subAgencydata?.id_agencia 
            }
            break;
          default:                         
            break;            
        }

        token && params.append('token', token)
        agency_id_acording_to_level && params.append('agencia', agency_id_acording_to_level)          
        datesMovementsPerDay.startDate && params.append('fecha_desde', datesMovementsPerDay.startDate)
        datesMovementsPerDay.endDate && params.append('fecha_hasta', datesMovementsPerDay.endDate)
        
        // Agregar `id_moneda` solo si es necesario
        // if (userData?.nivel_agencia === '3' && selectedAgency?.id_agencia === '14477') {
          
        // }
      }  
      try {
        const {
          data,
        }: { data: any } = await axiosConsolaCaballoNegro.get(url, {params});          
        if(data.respuesta.error =='0'){
          setLoading(false)              
          setNewDataPerDay(data.registros);
          setLoadingBody({
            ...loadingBody,
            perDayReport: false
          }) 
          if(data.registros == null){
            notification.info({
              message: 'Resultado', 
              description:
                'No hay registros de movimientos para ésta solicitud, realice otra consulta', 
              placement: 'topRight'
            })
          }
        }else {
          setLoading(false)
          notification.error({
            message: 'Error', 
            description:
              'ha ocurrido un error, comuníquese con el administrador', 
            placement: 'top'
          })
          setLoadingBody({
            ...loadingBody,
            perDayReport: false
          }) 
        }
      } catch (newError) {
        setLoadingBody({
          ...loadingBody,
          perDayReport: false
        }) 
        setLoading(false)
        console.log(newError);
        throw newError;
      }
    };
      
    const filterByDatePerDate = async(
    )=>{
      {
        let currentDateAndTime = new Date();
        if(tokenExpirationTime && tokeRefreshTime){

          let date_token_vida = new Date(tokenExpirationTime)
          let date_token_regen = new Date(tokeRefreshTime)
          if(currentDateAndTime < date_token_vida ){ //consulto si se venció el token
            fetcher()
          }else{ 
            try {
              const {
                data,
              }: { data: any } = await axiosConsolaCaballoNegro.get(
              `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
              );          
              if(data){
                setLoading(false)
                switch(data.error){
                  case '0':  
                    let token_life = addSecondsToDate(data.token_vida)            
                    let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
                    setCaballoNegroToken(data.token)  
                    setRegenerateToken(data.token_reneg)
                    setTokenExpirationTime(token_life.date)
                    setTokeRefreshTime(token_regen_life.date)  
                    fetcher(data.token_reneg,)
                    break;
                  case '-3':
                    logOut()
                    notification.error({
                      message: 'Logout', 
                      description:
                      'La sesión se ha vencido, vuelva a loguearse', 
                      placement: 'top'
                    })
                  break;
                  default: 
                    console.log(data.error)
                }
              }
            } catch (newError) {
              logOut()
              setLoading(false)
              console.log(newError);
              throw newError;
            }       
          }          
        }
      }
    }


    
   return{filterByDatePerDate}
}