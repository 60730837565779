import React, {useContext, useEffect, useState} from 'react';
import { Form, Button, DatePicker, Input, Row, Col } from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useTicketsReport } from '../../Services/useTicketsReport';
import './styles.scss'
import { usePerDayReport } from '../../Services/usePerDayReport';
import { useSearchTicket } from '../../Services/useSearchTicket';
import type { Dayjs } from 'dayjs';
import { useWindowSize } from '../../hooks/useWindowSize';
import dayjs from 'dayjs';
import { UserContext } from '../../Context/UserContext/UsersContext';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const MovementsFilters: React.FC =()=>{

  const {
    typeMovements, 
    setTicketsLoading,
    ticketsLoading,
    setIsLoadingAllTickets,
    setNewDataTickets,
    setSearchTicketsNull,
    setNewDataPerDay,
    setLoadingBody,
    loadingBody,
    seeAllTickets, 
    setSeeAllTickets,    
    datesMovementsPerDay, 
    setDatesMovementsPerDay,
    datesMovementsTickets, 
    setDatesMovementsTickets,
  } = useContext(BodyContext)

  const {filterByDate} = useTicketsReport()
  const {filterByDatePerDate } = usePerDayReport()
  const {RangePicker} = DatePicker;
  const {ancho} = useWindowSize()
  const {searchTicket} = useSearchTicket()  
  const {subAgencydata} = useContext(UserContext)

  const [form] = Form.useForm();
  const [formPicker] = Form.useForm();
  const [form_Tickets_picker] = Form.useForm();
  const [formData, setFormData] = useState<string>('')
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const [loadingSearch, setLoadingSearch] = useState(false)
  
  useEffect(()=>{
      if(datesMovementsPerDay && subAgencydata){
          setLoadingBody({
              ...loadingBody,
              perDayReport: true
          }) 
          filterByDatePerDate() 
      }   
  },[datesMovementsPerDay, subAgencydata])

  useEffect(()=>{
    if(datesMovementsTickets){
      setTicketsLoading({
        ...ticketsLoading,
        loading: true,
      }) 
      filterByDate() 
    }   
  },[datesMovementsTickets, subAgencydata])

  const handleSearch = async (event: any) =>{
    setSeeAllTickets(true)
    setSearchTicketsNull(undefined)
    setNewDataTickets(undefined)
    setTicketsLoading({
      ...ticketsLoading,
      loading: true,
    }) 
    await searchTicket(formData, 'shareTicket')
  }

  const handleChange = (event: any)=>{
    event.preventDefault()
    const name = event.target.name 
    const value = event.target.value
    setFormData(value)
  };    

  const handleCalendar = async(event: any)=>{
    if(typeMovements == 'tickets'){
      setSearchTicketsNull(undefined)
      const startDate = event[0].format('YYYY/M/DD')
      const endDate =  event[1].format('YYYY/M/DD')
      setDatesMovementsTickets({
        startDate: startDate,
        endDate: endDate
      })
      setNewDataTickets(undefined)
    }else if(typeMovements === 'per_day'){
      setNewDataPerDay(undefined);
      // const startDate = event.date_Picker_p[0].format('YYYY/M/DD')
      // const dateEnd =  event.date_Picker_p[1].format('YYYY/M/DD')
      setDatesMovementsPerDay({
        startDate: event.date_Picker_p[0].format('YYYY/M/DD'),
        endDate:  event.date_Picker_p[1].format('YYYY/M/DD')
      })
    }       
  }

  const handleSeeAll =()=>{
    setSearchTicketsNull(undefined)
    setSeeAllTickets(!seeAllTickets)
    if(!seeAllTickets){
      setIsLoadingAllTickets(true)
      setTimeout(()=>{
        setIsLoadingAllTickets(false)
      },2000)
    }
  }

  const handleReset = ()=>{
    setNewDataTickets(undefined) 
    form.setFieldsValue({
      ticketsNumber: ''  
    });
  } 

   const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 3;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 3;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    if (datesMovementsPerDay) {
      const formattedStart = datesMovementsPerDay.startDate.replace(/\//g, '-').replace(/'/g, '"');
      const formattedEnd = datesMovementsPerDay.endDate.replace(/\//g, '-').replace(/'/g, '"');
  
      const start = dayjs(`${formattedStart}`);
      const end = dayjs(`${formattedEnd}`);
  
      if (start.isValid() && end.isValid()) {
        formPicker.setFieldsValue({
          date_Picker_p: [start, end]
        });
      } else {
        console.error('Fecha inválida:', formattedStart, formattedEnd);
      }
    }
  }, [datesMovementsPerDay]);

  return(
    <div className="movimentsFilters_container">
      {ancho < 1300 &&
        <>
          { typeMovements === 'per_day' &&
            <Form
              form={formPicker}
              onFinish={handleCalendar}  
              style={{margin: '0px', padding:'0px'}}
              layout="inline"
            >            
              <Form.Item 
                name='date_Picker_p'
                label='Fechas'
                className="movimentsFilters_datePicker"
                style={{margin: '0px'}}
                >
                  <RangePicker  
                  placeholder={['Desde', 'Hasta']}
                  />
              </Form.Item>
              <Form.Item>             
                <Button
                  type="ghost"
                  className="movimentsFilters_filtersBtn"
                  loading={loadingSearch}
                  htmlType="submit"
                  style={{marginLeft: 5}}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Form> 
          }
          {  typeMovements === 'tickets' &&
            <>
              <Form
                form={form_Tickets_picker}
                onFinish={handleCalendar}  
                style={{    height: '40px'}}
              >
                <Row style={{width: ancho > 820 ? '50%' : ' 70%'}}>
                  <Col span={14}>
                      <Form.Item 
                        name='datePicker'
                        label='Fechas'
                        style={{marginRight:'10px'}}
                        className="movimentsFilters_datePicker">
                          <RangePicker  
                            value={dates || value}
                            onCalendarChange={(val: any) => {
                              setDates(val);
                            }}
                            onChange={handleCalendar}
                            disabledDate={(e:any)=>disabledDate(e)}
                            onOpenChange={onOpenChange}
                            placeholder={['Desde', 'Hasta']}
                            inputReadOnly
                          />
                      </Form.Item>                 
                  </Col>
                  <Col span={10}>
                    <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      loading={loadingSearch}
                      onClick={handleSeeAll}
                    >
                      {seeAllTickets ? 'Ocultar tickets' : 'Ver tickets' }
                    </Button>
                  </Col>
                </Row>
              </Form>              
                <Form
                  form={form}
                  style={{    height: '40px'}}
                > 
                  <Row style={{width: ancho > 820 ? '50%' : ' 70%'}}>
                    <Col span={14}>
                      <Form.Item
                        style={{color:'white', marginRight:'10px'}}
                        name="ticketsNumber"
                        className="movimentsFilters_selectColor"
                        label={'Buscar Ticket'}>
                        <Input
                          style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white', }}
                          type='number'
                          placeholder='N° ticket'
                          onChange={handleChange}
                          value={formData}
                      />                
                      </Form.Item >
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        className='movimentsFilters_container-btn'   
                      >
                        <Button
                          type="ghost"
                          className="movimentsFilters_filtersBtn"
                          htmlType="submit"
                          loading={loadingSearch}
                          onClick={handleSearch}
                        >
                          Buscar
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Button
                            type="ghost"
                            className="movimentsFilters_filtersBtn"
                            loading={loadingSearch}
                            onClick={handleReset}
                          >
                            Cancelar
                        </Button>  
                    </Col>
                  </Row>                     
                </Form>
                 
            </>
          }
        </>
      }
      {ancho > 1300 &&
        <>
          <p><div className='movimentsFilters_point'> </div> FILTROS</p>
          {
            typeMovements === 'per_day' &&
            <Form
              form={formPicker}
              onFinish={handleCalendar}  
            >
            
                <Form.Item 
                  name='date_Picker_p'
                  className="movimentsFilters_datePicker">
                    <RangePicker  
                      style={{ width: '100%'}}
                    />
                </Form.Item>
                <Button
                    type="ghost"
                    className="movimentsFilters_filtersBtn width"
                    loading={loadingSearch}
                     htmlType="submit"
                  >
                    Buscar
                  </Button>
            </Form> 
          }
          {  typeMovements === 'tickets' &&
            <>
              <Form
                form={form_Tickets_picker}
                onFinish={handleCalendar}  
              >
                <Form.Item 
                  name='datePicker'
                  className="movimentsFilters_datePicker">
                    <RangePicker  
                      value={dates || value}
                      style={{ width: '100%'}}
                      onCalendarChange={(val: any) => {
                        setDates(val);
                      }}
                      onChange={handleCalendar}
                      disabledDate={(e: any)=>disabledDate(e)}
                      onOpenChange={onOpenChange}
                      //blurOnSelect 
                    />
                </Form.Item>
                  <Button
                    type="ghost"
                    className="movimentsFilters_filtersBtn width"
                    loading={loadingSearch}
                    onClick={handleSeeAll}
                  >
                    {seeAllTickets ? 'Ocultar tickets' : 'Ver tickets' }
                  </Button>
              </Form>

            <Form
              layout="vertical"
              form={form}
            > 
              <p style={{marginTop:'70px'  }}>Buscar Ticket</p>
              <Form.Item
                style={{color:'white'}}
                name="ticketsNumber"
                className="movimentsFilters_selectColor">
                <Input
                  style={{backgroundColor: 'transparent', color: 'white', borderColor: 'white'}}
                  type='number'
                  placeholder='N° ticket'
                  onChange={handleChange}
                  value={formData}
              />                
              </Form.Item >
              <Row  justify="center">
                <Col span={12}>
                  <Form.Item
                    className='movimentsFilters_container-btn'          
                  >
                    <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      htmlType="submit"
                      loading={loadingSearch}
                      onClick={handleSearch}
                    >
                      Buscar
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                      type="ghost"
                      className="movimentsFilters_filtersBtn"
                      loading={loadingSearch}
                      onClick={handleReset}
                    >
                      Cancelar
                  </Button>
                </Col>
              
              </Row>
              
            </Form>
            </>
          }
        </>
      }
    </div>
  )
}

export default MovementsFilters;

