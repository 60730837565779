import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{API_END_POINT_REPORTE_TRANSACTION_FECHA, API_END_POINT_REPORTE_TOKEN_RENEG, API_END_POINT_REPORTE_TRANSACTION_FECHA_MONEDA} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { useUser } from './useUser';
import { TransactionsContext } from '../Context/TransactionsContext.tsx/TransactionsContext';

export interface TransactionsPerDay{
  credit_total: string,
  debe_total: string,
  fecha: string,
  cantidad_tickets: string,
  saldo: number,
}

export const useTransactionsPerDAy =  ()=>{

    const { 
      caballoNegroToken, 
      regenerateToken,
      userData, 
      subAgencydata, 
      tokenExpirationTime, 
      tokeRefreshTime,
      setCaballoNegroToken,
      setRegenerateToken, 
      setTokenExpirationTime,
      setTokeRefreshTime,
      selectedAgency
    } = useContext(UserContext)

    const {   
      setTransactionsLoading,
      transactionsLoading,
      setDataGroupedOfTransactions,
      dateAndHours
      }= useContext(TransactionsContext)

    const {logOut}= useUser()
    const fetcher = async (
      newToken?: string
    ): Promise<void>  => {

        let url = API_END_POINT_REPORTE_TRANSACTION_FECHA
        const token = newToken ? newToken : caballoNegroToken
        let agency_id_acording_to_level: string | undefined
        let params = new URLSearchParams

        if(dateAndHours && userData){
          let dates = dateAndHours
          switch(userData.nivel_agencia){
            case '1': //Uso el id directamente de la agencia que viene en el usuario
              agency_id_acording_to_level = userData?.id_agencia 
              break;
            case '0': //uso la subagencia elegida
              agency_id_acording_to_level = subAgencydata?.id_agencia 
              break
            case '3':
              if(userData.por_moneda == 1){// si es 1 es true, uso el endpoitn por moneda
                agency_id_acording_to_level = selectedAgency?.id_agencia
                params.append('id_moneda', subAgencydata?.id_moneda ?? '');
                url = API_END_POINT_REPORTE_TRANSACTION_FECHA_MONEDA
              }else{
                agency_id_acording_to_level = subAgencydata?.id_agencia 
              }

              // if(selectedAgency && selectedAgency.id_agencia === '14477'){// si es agencia BETARON, MUESTRO DIRECTAMENTE POR MONEDA
              //   agency_id_acording_to_level = selectedAgency?.id_agencia
              // }
              break;
            default:                         
              break;            
          }

          token && params.append('token', token)
          agency_id_acording_to_level && params.append('agencia', agency_id_acording_to_level)          
          dates.firstDate && params.append('fecha_desde', dates.firstDate)
          dates.firstHours && params.append('hora_desde', dates.firstHours)
          dates.secondDate && params.append('fecha_hasta', dates.secondDate)
          dates.secondHours && params.append('hora_hasta', dates.secondHours)
          
          // Agregar `id_moneda` solo si es necesario
          // if (userData?.nivel_agencia === '3' && selectedAgency?.id_agencia === '14477') {
          //   params.append('id_moneda', subAgencydata?.id_moneda ?? '');
          //   url = API_END_POINT_REPORTE_TRANSACTION_FECHA_MONEDA
          // }
        }    
        try {
          const {
            data,
          }: { data: any } = await axiosConsolaCaballoNegro.get(url, {params});          
          if(data.respuesta.error == '0'){
            setDataGroupedOfTransactions(data.registros)
            setTransactionsLoading({
              ...transactionsLoading,
              getTransactionsPerDay: false              
            })
            if(data.registros == null){
              notification.info({
                message: 'Resultado', 
                description:
                  'No hay registros de transacciones para ésta solicitud, realice otra consulta', 
                placement: 'topRight'
              })
            }
          }else {
            setTransactionsLoading({
              ...transactionsLoading,
              getTransactionsPerDay: false              
            })
            notification.error({
              message: 'Error', 
              description:
                'ha ocurrido un error, comuníquese con el administrador', 
              placement: 'top'
            })
          }
        } catch (newError) {
          setTransactionsLoading({
            ...transactionsLoading,
            getTransactionsPerDay: false              
          }) 
          notification.error({
            message: 'Error', 
            description:
              'ha ocurrido un error, comuníquese con el administrador', 
            placement: 'top'
          })
          console.log(newError);
          throw newError;           
        }
      };
    
      const filterTransactionsPerDay = async(
      )=>{
        {
          let currentDateAndTime = new Date();
          if(tokenExpirationTime && tokeRefreshTime){

            let date_token_vida = new Date(tokenExpirationTime)
            let date_token_regen = new Date(tokeRefreshTime)
            if(currentDateAndTime < date_token_vida ){ //consulto si se venció el token
              fetcher()
            }else{ 
                try {
                  const {
                    data,
                  }: { data: any } = await axiosConsolaCaballoNegro.get(
                  `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
                  );          
                  if(data){
                    switch(data.error){
                      case '0':  
                        let token_life = addSecondsToDate(data.token_vida)            
                        let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
                        setCaballoNegroToken(data.token) 
                        setRegenerateToken(data.token_reneg)
                        setTokenExpirationTime(token_life.date)
                        setTokeRefreshTime(token_regen_life.date)  
                        fetcher(data.token )
                        break;
                      case '-3':
                        logOut()
                        notification.error({
                          message: 'Logout', 
                          description:
                          'La sesión se ha vencido, vuelva a loguearse', 
                          placement: 'top'
                        })
                        break;
                      default: 
                        console.log(data.error)
                      break;
                    }
                  }
                } catch (newError) {
                  console.log(newError);
                  throw newError;
                } 
              // }else{ // si se vención deslogueamos el usuario
              //   logOut()
              // }         
            }          
          }
        }
      }
   return{filterTransactionsPerDay}
}