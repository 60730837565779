import React, {useContext, useEffect, useState} from 'react';
import { Form, Button, DatePicker} from 'antd';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import './styles.scss'
import { usePerDayReport } from '../../Services/usePerDayReport';
import type { Dayjs } from 'dayjs';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { useNavbarBottom } from '../NavbarBottom/useNavbarBottom';

import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import { UserContext } from '../../Context/UserContext/UsersContext';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const Panel_MovementFiltersPerDay: React.FC =()=>{
  const [dates, setDates] = useState<Dayjs | null>(null);

  const {
    setNewDataPerDay,
    setLoadingBody,
    loadingBody,    
    setDatesMovementsPerDay,
    datesMovementsPerDay
  } = useContext(BodyContext)

  const {
    subAgencydata
  } = useContext(UserContext)

  const {
    controllerNavbarBottom,
  } = useContext(MobileContext)

  const {filterByDatePerDate } = usePerDayReport()
  const { onClick_navbarBottom } = useNavbarBottom()
 
  const [formPicker] = Form.useForm();

  useEffect(()=>{
      if(datesMovementsPerDay && subAgencydata){
          setLoadingBody({
              ...loadingBody,
              perDayReport: true
          }) 
          filterByDatePerDate() 
      }   
  },[datesMovementsPerDay, subAgencydata])

  useEffect(() => {
    if (datesMovementsPerDay) {
      const formattedStart = datesMovementsPerDay.startDate.replace(/\//g, '-').replace(/'/g, '"');
      const formattedEnd = datesMovementsPerDay.endDate.replace(/\//g, '-').replace(/'/g, '"');
  
      const start = dayjs(`${formattedStart}`);
      const end = dayjs(`${formattedEnd}`);
  
      if (start.isValid() && end.isValid()) {
        formPicker.setFieldsValue({
          dateFrom: start,
          dateUntil: end

        });
      } else {
        console.error('Fecha inválida:', formattedStart, formattedEnd);
      }
    }
  }, [datesMovementsPerDay]);
 
  const handleCalendar = async()=>{    
      setNewDataPerDay(undefined);
      const startDate = formPicker.getFieldValue('dateFrom').format('YYYY/M/DD')
      const endDate = formPicker.getFieldValue('dateUntil').format('YYYY/M/DD')
      setDatesMovementsPerDay({
        startDate: startDate,
        endDate: endDate
      })
      onClick_navbarBottom('movementsPerDayRangePicker')
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!dates) {
      return true;
    }
     return current.isBefore(dates, 'day') || current.isAfter(dates.add(2, 'days'), 'day');
  };

  return(
    <div className= {`movementsFiltersMobile_container ${controllerNavbarBottom.movementsPerDayRangePicker  ? 'visible' : 'hidden'}`} >
      <div className='movementsFiltersMobile_subContainers'>
        <p><div className='movementsFiltersMobile_point'> </div> BUSQUEDA POR FECHAS</p>
          <Form
            form={formPicker}
            id='form_picker'
            onFinish={handleCalendar}  
            style={{marginTop:'50px'}}
          >         
            <Form.Item 
              name='dateFrom'
              className="movementsFiltersMobile_datePicker">
                 <DatePicker
                  inputReadOnly
                  style={{ width: '100%'}}
                  placeholder='Fecha desde'
                  onChange={(e)=>setDates(e)}
                />
            </Form.Item>
            <Form.Item 
            name='dateUntil'
            className="movementsFiltersMobile_datePicker">
              <DatePicker
                placeholder='Fecha hasta'
                inputReadOnly
                style={{ width: '100%'}}
              />
          </Form.Item>    
            <Button
              type='primary'
              key="submit" 
              form='form_picker'
              onClick={handleCalendar}
              style={{width: '100%'}}
            >
              Aplicar
            </Button>
        </Form>          
      </div>          
    </div>
  )
}

export default Panel_MovementFiltersPerDay;

