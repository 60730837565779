import useSWR from "swr";
import { axiosConsolaCaballoNegro } from '../axios';
import { API_END_POINT_REPORTE_DATOS_USUARIOS, } from '../axios/consolaIntegrationCaballoNegro'
import { useContext } from "react";
import { UserContext, UserData } from "../Context/UserContext/UsersContext";

export interface Record {
  cantidad_afiliados: string,
  cantidad_usuarios: string,
  descripcion: string,
  id_agencia: string,
  moneda: string,
  moneda_desc: string,
  moneda_resum: string,
  num_usuario: string,
  saldo: number
}

export const useGetUserData = (
  token: string | undefined,
  ) => {

    const { 
      setUserData,
    } = useContext(UserContext)
      
    const fetcher = async (
      path: string,
      token: string | undefined): Promise<UserData | undefined> => {
        try {
          const {
            data,
          }: { data: any } = await axiosConsolaCaballoNegro.get(
          path + `?token=${token}`
          );          
          if(data){
            setUserData(data)
            return data
          }
        } catch (newError) {
          console.log(newError);
          throw newError;
        }  
    };
  
    const { data } = useSWR([API_END_POINT_REPORTE_DATOS_USUARIOS, token], ([url, token]) => fetcher(url, token))
    
  return {
    data
  };
};

