import React, {useContext, useEffect, useState, useTransition} from 'react';
import { Form, Button, DatePicker, Select,  Tag,  Input, TimePicker, DatePickerProps, Row, Col } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import './styles.scss'
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import moment, { Moment } from 'moment';
import { useAllTransactions } from '../../Services/useAllTransactions';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma español si lo necesitas
import { useSearchTransactions } from '../../Services/useSearchTransaction';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MobileContext } from '../../Context/MobileContext/MobileContext';
import { ScreenData } from '../HamburgerMenu/types';
import { UserContext } from '../../Context/UserContext/UsersContext';

interface filterInformation{
    first_date: Dayjs,
    first_hours: Dayjs,
    second_date: Dayjs,
    second_hours: Dayjs
  }
const tagRender = (props: CustomTagProps) => {

    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };    

    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };  

  export interface NotificationFilters {
    colors: string[] | undefined,
    datePicker: Date[] | undefined,
    machines:  number[] | undefined,
    organizations: number[]| undefined,
  }

  export interface TransactionsRangePicker_props{
    selectedScreen: ScreenData
  }
export const Panel_TransactionsRangePicker: React.FC<TransactionsRangePicker_props> = ({
  selectedScreen
})=>{

  const { 
    dateAndHours, 
    setTransactionsLoading, 
    transactionsLoading, 
    setDataGroupedOfTransactions,
    typeTransactions,
    setDataOfAllTransactions,
    dateAndHoursAllTransactions,
    setDateAndHours,
    setDateAndHoursAllTransactions
  } = useContext(TransactionsContext)

  const {subAgencydata} = useContext(UserContext)
  const {controllerNavbarBottom, setControllerNavbarBottom} = useContext(MobileContext)
  const {filterTransactionsPerDay} = useTransactionsPerDAy()
  const {searchTransactionsServise} = useSearchTransactions()
  const {filterAllTransactions} = useAllTransactions()
  

  const [form_transactions] = Form.useForm();  
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  
  // useEffect(()=>{
  //   form_transactions.resetFields()
  // },[typeTransactions])

  useEffect(()=>{
      if(dateAndHours && subAgencydata){
        setTransactionsLoading({
          ...transactionsLoading,
          getTransactionsPerDay: true              
        })           
          filterTransactionsPerDay() 
      }   
  },[dateAndHours, subAgencydata])

  useEffect(()=>{
    if(dateAndHoursAllTransactions && subAgencydata){
      setTransactionsLoading({
        ...transactionsLoading,
        getTransactionsPerDay: true              
      })
        filterAllTransactions()           
    }   
  },[dateAndHoursAllTransactions, subAgencydata])

  const handleTransactionsFilter = async(e: any) =>{
    // setTransactionsLoading({
    //   ...transactionsLoading,
    //   getTransactionsPerDay: true              
    // })
    let first_date = e.first_date.format('YYYY/M/DD')
    let first_hours = e.first_hours.format('HH:mm:ss')
    let second_date = e.second_date.format('YYYY/M/DD')
    let second_hours = e.second_hours.format('HH:mm:ss')
    if(selectedScreen.name === 'transactions'){
      setDataOfAllTransactions(undefined)
      setDateAndHoursAllTransactions({
        firstDate: first_date,
        firstHours: first_hours,
        secondDate: second_date,
        secondHours: second_hours,
      })
      //await filterAllTransactions(first_date, first_hours, second_date, second_hours)
    }else if(selectedScreen.name === 'transactionsPerDay'){
      setDataGroupedOfTransactions(undefined)
      setDateAndHours({
        firstDate: first_date,
        firstHours: first_hours,
        secondDate: second_date,
        secondHours: second_hours,
      })
      //await filterTransactionsPerDay(first_date, first_hours, second_date, second_hours)
    }
   
    setControllerNavbarBottom(
      {   
        movementsPerDayRangePicker: false,
        movementsTicketsRangePicker: false,
        searchTicket: false,
        showTickets: false,
        transactionsPerDayRangePicker: false,
        transactionsRangePicker: false,
        transactions: false,
        showTransactions: false}
    )       
  }


  const disabledEndDate = (current: Dayjs | null) => {
    if(selectedScreen.name === 'transactions'){ 
      if (startDate && current) {
        const minDate = dayjs(startDate).subtract(0, 'days');
        const maxDate = dayjs(startDate).add(3, 'days');
        return (current && (current < minDate || current > maxDate));
      }
      return true;
    }else{
      return false
    }
  };

  const handleChangePicker = (date: Dayjs | null) => {
    setStartDate(date);
    form_transactions.setFieldsValue({
      second_date: undefined
    });
  };

    useEffect(() => {
      if(selectedScreen.name === 'transactionsPerDay'){
        if (dateAndHours) {    
          let  first_date = dayjs(`${dateAndHours.firstDate}`)
          let  first_hours = dayjs(dateAndHours.firstHours, 'HH:mm:ss')
          let  second_date  = dayjs(`${dateAndHours.secondDate}` )
          let  second_hours = dayjs(dateAndHours.secondHours, 'HH:mm:ss')   
        
          if (first_date.isValid() && second_date.isValid() && first_hours.isValid() && second_hours.isValid()) {
            form_transactions.setFieldsValue({
              first_date: first_date,
              first_hours: first_hours,
              second_date: second_date,
              second_hours: second_hours
            });
          } else {
            console.error('Fecha inválida:');
          }
        }
      }else if(selectedScreen.name === 'transactions'){
        if (dateAndHoursAllTransactions) {    
          let  first_date = dayjs(`${dateAndHoursAllTransactions.firstDate}`)
          let  first_hours = dayjs(dateAndHoursAllTransactions.firstHours, 'HH:mm:ss')
          let  second_date  = dayjs(`${dateAndHoursAllTransactions.secondDate}` )
          let  second_hours = dayjs(dateAndHoursAllTransactions.secondHours, 'HH:mm:ss')   
        
          if (first_date.isValid() && second_date.isValid() && first_hours.isValid() && second_hours.isValid()) {
            form_transactions.setFieldsValue({
              first_date: first_date,
              first_hours: first_hours,
              second_date: second_date,
              second_hours: second_hours
            });
          } else {
            console.error('Fecha inválida:');
          }
        }else{
          form_transactions.resetFields()
        }
      }
       
      }, [typeTransactions, dateAndHours, dateAndHoursAllTransactions ]);
  


  return(
    <div className=  {`Panel_TransactionsRangePicker_container ${controllerNavbarBottom.transactionsPerDayRangePicker || controllerNavbarBottom.transactionsRangePicker ? 'visible' : 'hidden'}`}>
      <div className="Panel_TransactionsRangePicker_subContainers">
        <p><div className='Panel_TransactionsRangePicker_point'> </div> BUSQUEDA POR FECHAS</p>
        <Form
          id='form_transactions'
          form={form_transactions}
          onFinish={handleTransactionsFilter}  
        >
          <Form.Item 
            label='Fecha desde'
            name='first_date'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar fecha',
              },
            ]}>
              <DatePicker 
                inputReadOnly
                style={{ width: '100%'}}
                onChange={handleChangePicker}
                //disabledDate={disabledDate}
              />
          </Form.Item>
          <Form.Item 
            label='Hora desde'
            name='first_hours'          
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar hora',
              },
            ]}
            > 
              <TimePicker  
                inputReadOnly
                style={{ width: '100%'}}/>
          </Form.Item>
          <Form.Item 
            name='second_date'
            label='Fecha hasta'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar fecha',
              },
            ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={disabledEndDate}
                inputReadOnly
                // onChange={handleEndDateChange}
              />
          </Form.Item>
          <Form.Item 
            name='second_hours'
            label='Hora hasta'
            className="Panel_TransactionsRangePicker_datePicker"
            rules={[
              {
                required: true,
                message: 'Seleccionar hora',
              },
            ]}
            >
              <TimePicker 
                style={{ width: '100%'}} 
                inputReadOnly
              />
          </Form.Item>          
          <Button
            form='form_transactions'
            type="primary"
            htmlType="submit"
            style={{width: '100%'}}
          >
            Aplicar
          </Button>                 
        </Form>   
      </div>   
    </div>
  )
}



