import React, {
    createContext,
    useState,
  } from 'react';

  import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { Record } from '../../Services/useGetListSubAgencies';

  class VoidContext implements UserContext {
    get changeAccessPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setChangeAccesPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
     get loading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get statusRequest():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStatusRequest(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get caballoNegroToken():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCaballoNegroToken(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get userData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setUserData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get listSubAgencies(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setListSubAgencies(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get subAgencydata(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSubAgencyData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get regenerateToken(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setRegenerateToken(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get tokenExpirationTime(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTokenExpirationTime(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get tokeRefreshTime(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setTokeRefreshTime(): never {
      throw new Error('Cannot consume context outside of provider');
    }
     get selectedAgency(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedAgency(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedAgencyCurrency(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedAgencyCurrency(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get ListCurrency (): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSListCurrency(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface UserContext {
    changeAccessPageForm: String,
    setChangeAccesPageForm: Function,
    loading: boolean,
    setLoading: Function,
    showError: string,
    setShowError: Function,
    caballoNegroToken : string | undefined
    setCaballoNegroToken: Function,
    userData: UserData  | undefined,
    setUserData: Function,
    listSubAgencies: Record[],
    setListSubAgencies: Function,
    subAgencydata: Record | undefined,
    setSubAgencyData: Function,
    regenerateToken:string | undefined,
    setRegenerateToken: Function,
    tokenExpirationTime: string | undefined,
    setTokenExpirationTime: Function,
    tokeRefreshTime: string | undefined,
    setTokeRefreshTime: Function,
    selectedAgency:Agency | undefined,
    setSelectedAgency: Function,
    selectedAgencyCurrency: {
      descripción: string,
      id_moneda: string
    } | undefined,
    setSelectedAgencyCurrency: Function,
    ListCurrency: {
      descripción: string,
      id_moneda: string
    }[] | undefined
    setSListCurrency: Function
    
  }
  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface caballoNegroToken{
    access: string,
    refresh:string
  }

  export interface UserData {
    usuario_id: string | undefined,
    usuario_nombre: string | undefined,
    id_agencia:string | undefined,
    nivel_agencia: string | undefined,
    agencia_madre_1: string | number | undefined | null,
    agencia_madre_2:string | number| undefined | null,
    agencia_nombre:string | undefined,
    moneda: string | undefined,
    moneda_desc: string | undefined,
    moneda_resum: string | undefined,
    por_moneda: number | undefined, //0 false, 1 verdadero
  }

  export interface ListSubagencies {
    record:Record
  }   

  export interface Agency{
    id_agencia: string,
    descripcion: string
  }
  
  export const UserContext = createContext<UserContext>(new VoidContext());
  export const UserContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const tokenToLocalStorage = window.localStorage.getItem('caballoNegroConsola-token')
    const refreshTokenLocalStorage = window.localStorage.getItem('caballoNegroConsola-refreshToken')
    const token_expiration_timeLocalStorage = window.localStorage.getItem('token_refresh_time')
    const token_refresh_timeLocalStorage = window.localStorage.getItem('token_refresh_time')


    const [caballoNegroToken, setCaballoNegroToken] = useLocalStorageState<string | undefined>(
      'caballoNegroConsola-token',
      tokenToLocalStorage ?  tokenToLocalStorage : undefined
    );  
    const [regenerateToken, setRegenerateToken] = useLocalStorageState<string | undefined>(
      'caballoNegroConsola-regenerateToken',
      refreshTokenLocalStorage ?  refreshTokenLocalStorage : undefined
    ); 
    const [tokenExpirationTime, setTokenExpirationTime] = useLocalStorageState<string | undefined>(
      'token_expiration_time',
      token_expiration_timeLocalStorage ?  token_expiration_timeLocalStorage : undefined
    ); 
    const [tokeRefreshTime, setTokeRefreshTime] = useLocalStorageState<string | undefined>(
      'token_refresh_time',
      token_refresh_timeLocalStorage ?  token_refresh_timeLocalStorage : undefined
    ); 

    const [changeAccessPageForm, setChangeAccesPageForm]= useState<string>('login')
    const [loading, setLoading]= useState<boolean>(false)
    const [showError, setShowError]= useState<string>('')
    const [userData, setUserData] = useState<UserData | undefined>()
    const [listSubAgencies, setListSubAgencies] = useState<Record[]>([])
    const [subAgencydata, setSubAgencyData] = useState<Record | undefined>()
    const [selectedAgency, setSelectedAgency] = useState<Agency | undefined>()
    const [selectedAgencyCurrency, setSelectedAgencyCurrency] = useState<{
      descripción: string,
      id_moneda: string
    } | undefined>()
    const [ListCurrency, setSListCurrency] = useState<{
      descripción: string,
      id_moneda: string
    }[] | undefined>()


    return (
      <UserContext.Provider value={{
        changeAccessPageForm, 
        setChangeAccesPageForm,
        showError,
        setShowError,
        loading,
        setLoading,
        caballoNegroToken, 
        setCaballoNegroToken,
        regenerateToken, 
        setRegenerateToken,
        userData, 
        setUserData,
        listSubAgencies,
        setListSubAgencies,
        subAgencydata, 
        setSubAgencyData,
        tokenExpirationTime, 
        setTokenExpirationTime,
        tokeRefreshTime, 
        setTokeRefreshTime,
        selectedAgency, 
        setSelectedAgency,
        selectedAgencyCurrency, 
        setSelectedAgencyCurrency,
        ListCurrency, setSListCurrency
      }}>{children}</UserContext.Provider>
    );
  };



  