const AUTH_END_POINT_LOGIN_CONSOLA = '/login_consola.php';
const API_END_PINT_LISTA_SUB_AGENCIA_CONSOLA = 'lista_sub_agencia_consola.php';
const API_END_PINT_LISTA_SUB_AGENCIA_CONSOLA_N1 = 'lista_sub_agencia_consola_n1.php';
const API_END_PINT_LISTA_AGENCIA_CONSOLA_N0 = 'lista_sub_agencia_consola_n0.php';


const API_END_POINT_REPORTE_TICKETS ='reporte_tickets.php';
const API_END_POINT_REPORTE_TICKETS_FECHA ='reporte_tickets_fecha.php';
const API_END_POINT_REPORTE_TICKETS_SOLO ='reporte_ticket_solo.php';
const API_END_POINT_REPORTE_TICKETS_TRANSACTIONS ='tickets_transactions.php';
const API_END_POINT_REPORTE_TICKETS_DATA ='datos_ticket.php';
const API_END_POINT_REPORTE_TOKEN_RENEG='reneg_token.php';
const API_END_POINT_REPORTE_DATOS_USUARIOS='datos_usuario.php';
const API_END_POINT_REPORTE_TRANSACTION ='reporte_transaction.php';
const API_END_POINT_REPORTE_TRANSACTION_FILTRO ='reporte_transaction_filtro.php';
const API_END_POINT_REPORTE_TRANSACTION_FECHA ='reporte_transaction_fecha.php';
const API_END_PINT_LIST_SUB_AGENCIA_MONEDA ='lista_sub_agencia_moneda_consola.php'
const API_END_POINT_REPORTE_TICKETS_FECHA_MONEDA ='reporte_tickets_fecha_moneda.php'
const API_END_POINT_REPORTE_TRANSACTION_FECHA_MONEDA ='reporte_transaction_fecha_moneda.php'
const API_END_POINT_REPORTE_TICKET_MONEDA ='reporte_tickets_moneda.php'
const API_END_POINT_REPORTE_TRANSACTION_MONEDA ='reporte_transaction_moneda.php'

export {
    AUTH_END_POINT_LOGIN_CONSOLA,
    API_END_PINT_LISTA_SUB_AGENCIA_CONSOLA,
    API_END_POINT_REPORTE_TICKETS,
    API_END_POINT_REPORTE_TICKETS_FECHA,
    API_END_POINT_REPORTE_TICKETS_SOLO,
    API_END_POINT_REPORTE_TICKETS_TRANSACTIONS,
    API_END_POINT_REPORTE_TICKETS_DATA,
    API_END_POINT_REPORTE_TOKEN_RENEG,
    API_END_POINT_REPORTE_DATOS_USUARIOS,
    API_END_POINT_REPORTE_TRANSACTION,
    API_END_POINT_REPORTE_TRANSACTION_FECHA,
    API_END_POINT_REPORTE_TRANSACTION_FILTRO,
    API_END_PINT_LISTA_SUB_AGENCIA_CONSOLA_N1,
    API_END_PINT_LISTA_AGENCIA_CONSOLA_N0,
    API_END_PINT_LIST_SUB_AGENCIA_MONEDA,
    API_END_POINT_REPORTE_TICKETS_FECHA_MONEDA,
    API_END_POINT_REPORTE_TRANSACTION_FECHA_MONEDA,
    API_END_POINT_REPORTE_TICKET_MONEDA,
    API_END_POINT_REPORTE_TRANSACTION_MONEDA
}