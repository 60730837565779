import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { Tooltip } from 'antd';

import './styles.scss'
import { TicketsPerDayReport } from '../../Services/useGetPerDayReport';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import Table, { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { TotalBalance } from '../TotalBalance/TotalBalance';

interface RowData {
    fecha: string,
    cantidad_tickets: string,
    total_apuestas: string,
    total_devuelto: string,
    total_ganado: string,
    total_saldo: string,
    key: number | string 
}

export const MovementsPerDay =()=>{
   
    const { subAgencydata, userData} = useContext(UserContext)
    const { datesMovementsPerDay, newDataPerDay, loadingBody} = useContext(BodyContext)
    
    const[idAccordingToLevel, setIdAccordingToLeave] =useState<string | undefined>(userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia)

    useEffect(()=>{
        if(idAccordingToLevel !== subAgencydata?.id_agencia){
            setIdAccordingToLeave(subAgencydata?.id_agencia)
        }
    },[newDataPerDay, subAgencydata])

   
    const rowData= ()=>{
        let data: RowData[]=[];
        let moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum
       
        newDataPerDay?.map((newDataTicket, index)=>{
            
            let totalSaldo = Intl.NumberFormat("de-DE").format((parseFloat(newDataTicket.total_apuesta) - parseFloat(newDataTicket.total_devolucion) - parseFloat(newDataTicket.total_ganado))) + ' ' + moneda
            let totalApuestas = Intl.NumberFormat("de-DE").format(parseFloat(newDataTicket.total_apuesta))
            let totalDevuelto = Intl.NumberFormat("de-DE").format(parseFloat(newDataTicket.total_devolucion))
            let totalGanado = Intl.NumberFormat("de-DE").format(parseFloat(newDataTicket.total_ganado))

            
            data.push({
                fecha: newDataTicket.fecha,
                cantidad_tickets: newDataTicket.cantidad_tickets,
                total_apuestas: totalApuestas  + ' ' + moneda,
                total_devuelto: totalDevuelto  + ' ' + moneda,
                total_ganado: totalGanado + ' ' + moneda,
                total_saldo: totalSaldo,
                key: index

            })
        })
        return data
    }

    const handleRenderer = (text: string, event: RowData,  ) =>{
        return <Tooltip
                placement="right" 
                title={text}
                > <a
                >{text}</a>
            </Tooltip>
    }

    const columns: ColumnsType<RowData> = [
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'index',
           // width: 130,
           // ellipsis: true,
            render: handleRenderer,
        },
        {
            title: 'Cantidad tickets',
            dataIndex: 'cantidad_tickets',
            key: 'index',
         //   ellipsis: true,
           // width: 120,
            render: handleRenderer,
        },
        {
            title: 'Total apuestas',
            dataIndex: 'total_apuestas',
            key: 'index',
            //ellipsis: true,
            //width: 120,
            render: handleRenderer,
        },
        {
            title: 'Total devuelto',
            dataIndex: 'total_devuelto',
            key: 'index',
           // ellipsis: true,
            //width: 200,
            render: handleRenderer,
        },
        {
            title: 'Total ganado',
            dataIndex: 'total_ganado',
            key: 'index',
           // ellipsis: true,
            //width: 60,
            render: handleRenderer,
        },   
        {
            title: 'Total saldo',
            dataIndex: 'total_saldo',
            key: 'index',
           // ellipsis: true,
            //width: 60,
            render: handleRenderer,
        },       
    ];

    
    const moneda = userData?.nivel_agencia == '1' ? userData.moneda_resum : subAgencydata?.moneda_resum;
    const totalBalance = ()=>{
        let totalApuesta = 0;
        let totalDevolucion = 0;
        let totalGanado = 0;
        newDataPerDay?.map((item:TicketsPerDayReport)=>{
            if(item.cancelado !='1'){
                totalApuesta = totalApuesta + parseFloat(item.total_apuesta);
                totalDevolucion = totalDevolucion + parseFloat(item.total_devolucion);
                totalGanado = totalGanado + parseFloat(item.total_ganado);
            }
        })
        let totalBalance= {
            totalApuesta: totalApuesta,
            totalDevolucion: totalDevolucion,
            totalGanado: totalGanado,
            totalSaldo:  totalApuesta - totalDevolucion - totalGanado
        }
        return totalBalance
    } 
    
    

    return(
        <div className='movimentsTicketsPerDay_containers'>
            {moneda && newDataPerDay && newDataPerDay.length !== 0 &&  datesMovementsPerDay &&
                <>
                    <p className='p_gmt'>Zona horaria: GMT-3.</p>
                        <TotalBalance 
                            moneda={moneda} 
                            startDateTickets= {datesMovementsPerDay.startDate}
                            endDateTickets={datesMovementsPerDay.endDate}
                            type={'perDay'}
                    />
                </>
            }     
            { newDataPerDay && newDataPerDay.length !== 0 &&
                <Table 
                    columns={columns} 
                    dataSource={rowData()}  
                    scroll={{ x: 1000 }}
                   // pagination={{ pageSize: 10 }}
                   // scroll={{ y: 470 }}
                />
            }
            { newDataPerDay && newDataPerDay.length == 0 &&
                <p style={{ marginLeft: 15, color: 'white'}}>No hay movimientos para esa búsqueda</p>
            }
            {loadingBody.perDayReport && <SpinerHorizontal info={'Loading'}/>  }
            { }
        </div>
    )
}

   
