import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro } from '../axios';
import{AUTH_END_POINT_LOGIN_CONSOLA} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext, UserData } from '../Context/UserContext/UsersContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';


export const useUser =  ()=>{
    const {
        setUserData, 
        setCaballoNegroToken,
        setLoading,
        setRegenerateToken,
        setTokenExpirationTime,
        setTokeRefreshTime,
    } = useContext(UserContext)
 
    //const setAuthorizationHeader = (token: string) => (axiosConsolaCaballoNegro.defaults.headers.common ['Authorization'] = token);
    //const removeAuthorizationHeader = ( ) => {delete axiosConsolaCaballoNegro.defaults.headers.common ['Authorization'];}

    const logOut = ()=>{
        setCaballoNegroToken('undefined')
        setRegenerateToken('undefined')
        setTokenExpirationTime('undefined')
        setTokeRefreshTime('undefined')
        window.location.reload();
    }
    
    const requestAccess = async(usuario:string, password:string, key: string)=>{
        let version = 1;
       
        try{
           const data = await axiosConsolaCaballoNegro.get(
            AUTH_END_POINT_LOGIN_CONSOLA,
                {
                    params: {
                        usuario: usuario,
                        password: password,
                        version: version,
                        clave: key,
                    },
                }
            )
           if(data.data.error == 0){
            let token_life_date = addSecondsToDate(data.data.token_vida)            
            let token_regen_life_date = addSecondsToDate(data.data.token_reneg_vida) 
            setUserData(data.data)
            setCaballoNegroToken(data.data.token)
            setRegenerateToken(data.data.token_reneg)
            setTokenExpirationTime(token_life_date.date)
            setTokeRefreshTime(token_regen_life_date.date)           
            setLoading(false)
            //getRegenerateToken(data.data.token)
           } else{
            return data.data
           } 
        }catch(e){
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'top'
            })
        } 
    }
    
   return{requestAccess, logOut}
}