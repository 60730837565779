import { SwapOutlined, HomeOutlined, SearchOutlined, DownloadOutlined, ReloadOutlined, FilterOutlined,EyeOutlined, EyeInvisibleOutlined, CloseOutlined } from '@ant-design/icons';
import { NumberToBodyMobile } from '../HamburgerMenu/types';
import { useContext, useEffect, useState } from 'react';
import { BodyMobile, ControllerNavbarBottom, MobileContext } from '../../Context/MobileContext/MobileContext';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { usePerDayReport } from '../../Services/usePerDayReport';
import { notification, message  } from 'antd';
import useExportCSV from '../Screen_MovementsTickets/useExportCSV';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';



export interface NavbarBottomProprs {}

export interface NavbarBottomData{
    icon: any,
    nameButton: string,
    onClick: ()=>void
}

export const useNavbarBottom =()=>{

    const {setControllerNavbarBottom, controllerNavbarBottom} = useContext(MobileContext)
    const { 
        datesMovementsPerDay, 
        setNewDataPerDay, 
        setLoadingBody, 
        loadingBody,
    } = useContext(BodyContext)

    const {
        dateAndHours,
        transactionsLoading,
        setTransactionsLoading,
        setDataGroupedOfTransactions
    } = useContext(TransactionsContext)


    const {filterByDatePerDate } = usePerDayReport()
    const {filterTransactionsPerDay} = useTransactionsPerDAy()

    const [messageApi, contextHolder] = message.useMessage();


    const onClick_navbarBottom = (property: keyof ControllerNavbarBottom) => {
        let updatedControllerNavbarBottom: ControllerNavbarBottom = {
            ...controllerNavbarBottom, 
        };
        Object.keys(updatedControllerNavbarBottom).forEach(key => {
            if (key !== property) {
                updatedControllerNavbarBottom[key as keyof ControllerNavbarBottom] = false;
            }
        });
        updatedControllerNavbarBottom[property] = !controllerNavbarBottom[property];
        setControllerNavbarBottom(updatedControllerNavbarBottom);
    };

    const refresh_MovementPerDay = async()=>{
        if(datesMovementsPerDay){
            setNewDataPerDay(undefined);
            setLoadingBody({
                ...loadingBody,
                perDayReport: true
            })
            messageApi.info('Los datos se actualizarán con las últimas fechas consultadas');
            await filterByDatePerDate()
        }else{
            notification.info({ 
                message:'Aviso',
                description:
                  'No hay fechas cargadas', 
                placement: 'topRight'
            })
        }      
    }    

    const refresh_transactionsPerDay = ()=>{
        if(dateAndHours){
            setTransactionsLoading({
                ...transactionsLoading,
                getTransactionsPerDay: true              
                })
            setDataGroupedOfTransactions(undefined)
            messageApi.info('Los datos se actualizarán con las últimas fechas y horarios consultados');
            filterTransactionsPerDay()
        }else{
          notification.info({ 
            message:'Aviso',
            description:
              'No hay fechas cargadas', 
            placement: 'topRight'
          })  
    }
    }


    const MovementsPerDay: BodyMobile[] = [
        {   
            idName: 'Movement_perPay_filtro',
            nameButton: 'Filtro',
            onClick: 'movementsPerDayRangePicker',
            icon: <FilterOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/> 
        },
        {
            idName: 'Movement_perPay_refresh',
            nameButton: 'Refresh',
            onClick: 'refresh_MovementPerDay',
            icon: <ReloadOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>  
        }
      ]
    
    const MovementsTickets: BodyMobile[] = [
        {
            idName: 'Movement_tickets_filtro',
            nameButton: 'Filtro',
            onClick: 'movementsTicketsRangePicker',
            icon: <FilterOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>  
        },
        {
            idName: 'Movement_tickets_buscar',
            nameButton: 'Buscar',
            onClick: 'searchTicket',
            icon: <SearchOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>  
        },
        {
            idName: 'Movement_tickets_ticket',
            nameButton: 'Ticket',
            onClick: 'showTickets',
            icon:  <EyeInvisibleOutlined  style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>,  
            iconActive: <EyeOutlined style={{color:'red', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>
        },
        // {
        //     nameButton: 'Tabla',
        //     onClick: exportToCSV(),
        //     icon: <DownloadOutlined style={{color:'#1677ff', fontSize: '19px'}}/>,  
        // }
    ]
    
    const TrasnactionsPerDay: BodyMobile[] = [
        {
            idName: 'transactions_perDay_filtros',
            nameButton: 'Filtros',
            onClick: 'transactionsPerDayRangePicker',
            icon: <FilterOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>,  
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/> 
        },
        {
            idName: 'refresh_transactionsPerDay',
            nameButton: 'Refresh',
            onClick: 'refresh_transactionsPerDay',
            icon: <ReloadOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>  
        }
    ]
    
    const Trasnactions: BodyMobile[] = [
        {
            idName: 'transactions_transaction_filtro',
            nameButton: 'Filtro',
            onClick: 'transactionsRangePicker',
            icon: <FilterOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>,  
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/> 
        },
        {
            idName: 'transactions_transaction_buscar',
            nameButton: 'Buscar',
            onClick: 'transactions',
            icon: <SearchOutlined style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>, 
            iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>  
        },
        {
            idName: 'Movement_tickets_ticket',
            nameButton: 'Trans..',
            onClick: 'showTransactions',
            icon:  <EyeInvisibleOutlined  style={{color:'#1677ff', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>,  
            iconActive: <EyeOutlined style={{color:'red', fontSize: '19px', display: 'flex', alignItems: 'center' , justifyContent: 'center'}}/>
        },
        // {
        //     idName: 'transactions_transaction_tabla',
        //     nameButton: 'Tabla',
        //     onClick:'',
        //     icon: <DownloadOutlined style={{color:'#1677ff', fontSize: '19px'}}/>,  
        //     iconActive: <CloseOutlined style={{color:'red', fontSize: '17px', marginTop: '2px'}}/> 
        // }
    ]
    
    const mapeoNumeroAShowBody: NumberToBodyMobile = {
        1: MovementsPerDay,
        2: MovementsTickets,
        3: TrasnactionsPerDay,
        4: Trasnactions,
    }; 
    
    return{
        mapeoNumeroAShowBody,
        MovementsPerDay,
        MovementsTickets,
        TrasnactionsPerDay,
        Trasnactions,        
        onClick_navbarBottom,
        refresh_MovementPerDay,
        contextHolder,
        refresh_transactionsPerDay
    }
}


  
