import React, { useContext } from 'react'
import { axiosConsolaCaballoNegro, axiosInstance } from '../axios';
import{API_END_POINT_REPORTE_TRANSACTION_FILTRO, API_END_POINT_REPORTE_TOKEN_RENEG} from '../axios/consolaIntegrationCaballoNegro';
import { notification } from 'antd';
import { UserContext, UserData } from '../Context/UserContext/UsersContext';
import { addSecondsToDate } from '../utils/addSecondsToDate';
import { useUser } from './useUser';
import { TransactionsContext } from '../Context/TransactionsContext.tsx/TransactionsContext';


export interface Transactions{
  credit: number,
  debit: string,
  fecha: string,
  id: string,
  monto: string,
  transactionId: string,
  transactionType: string, //'BET
  userId: string,
  roundId: string
}

export const useSearchTransactions =  ()=>{

    const { 
      caballoNegroToken, 
      regenerateToken,
      userData, 
      subAgencydata, 
      setLoading,  
      tokenExpirationTime, 
      tokeRefreshTime,
      setCaballoNegroToken,
      setRegenerateToken, 
      setTokenExpirationTime,
      setTokeRefreshTime,
    } = useContext(UserContext)

    const {   
      setTransactionsLoading,
      transactionsLoading,
      setDataOfAllTransactions,
      setDateAndHoursAllTransactions,
      dateAndHours
      }= useContext(TransactionsContext)
 
    

    const {logOut}= useUser()
    const fetcher = async (
        values:{
          transactionId: string,
          selectedSubAgency?: string
        },  
        newToken?: string
        ): Promise<void>  => {
        const token = newToken ? newToken : caballoNegroToken
        let id_according_to_level = values.selectedSubAgency ? values.selectedSubAgency : userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia
        try {
          const {
            data,
          }: { data: any } = await axiosConsolaCaballoNegro.get(
           `${API_END_POINT_REPORTE_TRANSACTION_FILTRO}?token=${token}&agencia=${userData?.id_agencia}&id=${values.transactionId}`
          );          
          if(data.respuesta.error == '0'){
            setDataOfAllTransactions(data.registros)
            setTransactionsLoading({
              ...transactionsLoading,
              getTransactionsPerDay: false              
            })
            if(data.registros == null){
              //setDateAndHoursAllTransactions(undefined)
              notification.info({
                message: 'Resultado', 
                description:
                  'No hay registros para ésta solicitud, realice otra consulta', 
                placement: 'topRight'
              })
            }
          }else {
            setTransactionsLoading({
              ...transactionsLoading,
              getTransactionsPerDay: false              
            })
            notification.error({
              message: 'Error', 
              description:
                'ha ocurrido un error, comuníquese con el administrador', 
              placement: 'top'
            })
          }
        } catch (newError) {
          setTransactionsLoading({
            ...transactionsLoading,
            getTransactionsPerDay: false              
          }) 
          notification.error({
            message: 'Error', 
            description:
              'ha ocurrido un error, comuníquese con el administrador', 
            placement: 'top'
          })
          console.log(newError);
          throw newError;           
        }
      };
    
      const searchTransactionsServise = async(
        transactionId: string,
        selectedSubAgency?:string,
      )=>{
        {
          let currentDateAndTime = new Date();
          if(tokenExpirationTime && tokeRefreshTime){

            let date_token_vida = new Date(tokenExpirationTime)
            let date_token_regen = new Date(tokeRefreshTime)
            if(currentDateAndTime < date_token_vida ){ //consulto si se venció el token
              fetcher({transactionId, selectedSubAgency })
            }else{ 
                try {
                  const {
                    data,
                  }: { data: any } = await axiosConsolaCaballoNegro.get(
                  `${API_END_POINT_REPORTE_TOKEN_RENEG}?token=${regenerateToken}`
                  );          
                  if(data){
                    switch(data.error){
                      case '0':  
                        let token_life = addSecondsToDate(data.token_vida)            
                        let token_regen_life = addSecondsToDate(data.token_reneg_vida)   
                        setCaballoNegroToken(data.token) 
                        setRegenerateToken(data.token_reneg)
                        setTokenExpirationTime(token_life.date)
                        setTokeRefreshTime(token_regen_life.date)  
                        fetcher({transactionId, selectedSubAgency}, data.token )
                        break;
                      case '-3':
                        logOut()
                        notification.error({
                          message: 'Logout', 
                          description:
                          'La sesión se ha vencido, vuelva a loguearse', 
                          placement: 'top'
                        })
                        break;
                      default: 
                        console.log(data.error)
                      break;
                    }
                  }
                } catch (newError) {
                  console.log(newError);
                  throw newError;
                } 
              // }else{ // si se vención deslogueamos el usuario
              //   logOut()
              // }         
            }          
          }
        }
      }
   return{searchTransactionsServise}
}