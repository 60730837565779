import React, {useContext, useEffect, useRef, useState} from 'react';
import { DollarOutlined, EyeOutlined,EyeInvisibleOutlined  } from '@ant-design/icons';
import { UserContext } from '../../Context/UserContext/UsersContext';
import MovementsFilters from '../MovementsFilters/MovementsFilters';

import './styles.scss'
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { AllTransactions } from '../AllTransactions/AllTransactions';
import TransactionsFilters from '../TransactionsFilters/TransactionsFilters';
import { TransactionsPerDate } from '../TransactionsPerDate/TransactionsPerDate';
import { Button, Form, Tooltip, notification } from 'antd';
import { TransactionsContext } from '../../Context/TransactionsContext.tsx/TransactionsContext';
import { useTransactionsPerDAy } from '../../Services/useTransactionsPerDay';
import { useWindowSize } from '../../hooks/useWindowSize';
import ExportCSV from '../MovementsTickets/ExportCSV';
import ExportXLSXTransactions from '../ExportXLSXTransactions/ExportXLSXTransactions';


type AgencyMTransactions = {}

const AgencyTransactions: React.FC<AgencyMTransactions> = () =>{
    const { typeTransactions, setTypeTransactions} = useContext(TransactionsContext)

    return(
        <div>
            {typeTransactions === 'allTransactions' && <AllTransactions/>}
            {typeTransactions === 'perDay' && <TransactionsPerDate/>}
        </div>
    )
}

export const Transactions: React.FC =()=>{  
    
    const {subAgencydata, userData, setLoading} = useContext(UserContext)
    const { alto, ancho } = useWindowSize()
    const {
        typeTransactions, 
        setTypeTransactions, 
        dateAndHours, 
        setDataGroupedOfTransactions,
        setTransactionsLoading,
        transactionsLoading,
        dataOfAllTransactions
    } = useContext(TransactionsContext)

    const { 
        showFilters,
        setShowFilters
    } = useContext(BodyContext)

    const {filterTransactionsPerDay} = useTransactionsPerDAy()
    const refTableTransactions = useRef<any>(null)
    const[idAccordingToLevel, setIdAccordingToLeave] =useState<string | undefined>(userData?.nivel_agencia == '1' ? userData.id_agencia : subAgencydata?.id_agencia)

    const handleUploadData = ()=>{
        if(dateAndHours){
            setTransactionsLoading({
                ...transactionsLoading,
                getTransactionsPerDay: true              
                })
            setDataGroupedOfTransactions(undefined)
            filterTransactionsPerDay()
        }else{
          notification.info({ 
            message:'Aviso',
            description:
              'No hay fechas cargadas', 
            placement: 'topRight'
          })  
        }
    }

    return(
        <div className="transactions_container">
            <div className='transactions_title_container'>
                <div className='transactions_title_left'>
                    <DollarOutlined style={{ fontSize: '18px', color: 'white', marginLeft: '20px'}} />    
                    <p>Transacciones</p>
                    <div className="transactions_btnContainers">
                        <Button
                            type="ghost"
                            className="transactions_tabButton"
                            htmlType="submit"
                            onClick={()=>{
                                setTypeTransactions('perDay')
                            }}
                            style={typeTransactions ==='perDay' ? {borderBottom: '2px solid #1677ff', background: '#23272e', borderRadius: '3px'}: {borderBottom: ''} }
                        >
                            Por día
                        </Button>
                        <Button
                            type="ghost"
                            className="transactions_tabButton"
                            htmlType="submit"
                            onClick={()=>{
                                setTypeTransactions('allTransactions')
                            }}
                            style={typeTransactions ==='allTransactions' ? {borderBottom: '2px solid #1677ff', background: '#23272e', borderRadius: '3px'}: {borderBottom: ''} }
                        >
                            Transacciones
                        </Button>
                    </div>
                </div>
                <div>
                    { typeTransactions === 'perDay' &&
                        <Tooltip
                            placement="top" 
                            title='Los datos se actualizarán con las últimas fechas consultadas'
                        >
                            <Button
                                type="ghost"
                                className="transactions_upload"
                                htmlType="submit"
                                onClick={handleUploadData}
                            >
                                Actualizar datos
                            </Button>
                        </Tooltip>
                    }
                    { ancho < 1300 &&
                        <Button
                            type="ghost"
                            className="movements_upload"
                                onClick={()=>setShowFilters(!showFilters)}
                        >
                            Filtros { showFilters ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </Button>
                    }   
                    {
                        dataOfAllTransactions && dataOfAllTransactions.length !== 0 &&  typeTransactions === 'allTransactions' &&
                        <ExportXLSXTransactions data={dataOfAllTransactions}/>
                    }   
                </div> 
            </div>            
            <div className='transactions_body_container'>
                <div className='movements_filters'>
                    {
                        userData && userData.nivel_agencia === '1' && showFilters &&
                        <TransactionsFilters/>
                    }
                    {
                         userData &&(userData.nivel_agencia === '0' || userData.nivel_agencia === '3')  && showFilters &&

                            subAgencydata 
                            ? <TransactionsFilters/>
                            : <div></div>
                    }

                </div>
                <div className={ typeTransactions == 'perDay' ? 'transactions_timeline scrollTrue' : 'transactions_timeline'  }>
                    {
                        userData && userData.nivel_agencia === '1' &&
                        <AgencyTransactions/>
                    }
                    {
                        userData && (userData.nivel_agencia === '0' || userData.nivel_agencia === '3')  ?
                            subAgencydata 
                                ? <AgencyTransactions/>
                                : <p style={ancho < 1300 ? {color: 'red', marginLeft:'20px'} :{color: 'red'}}>Seleccione una agencia</p>    
                            : <div></div>                    
                    }                   
                </div>            
            </div>          
        </div>
    )
}

export default Transactions;

