import React, {
    createContext,
    useState,
  } from 'react';


  class VoidContext implements MobileContext {
    get isVisibleState(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setIsVisible(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showBodyMobile(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowBodyMobile(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get controllerNavbarBottom(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setControllerNavbarBottom(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get movimentsPerDayRangePicker(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMovimentsPerDayRangePicker(): never {
      throw new Error('Cannot consume context outside of provider');
    }

    
  }

  interface MobileContext {
    isVisibleState: boolean,
    setIsVisible: Function,
    showBodyMobile: BodyMobile [] | undefined,
    setShowBodyMobile: Function,
    controllerNavbarBottom: ControllerNavbarBottom
    setControllerNavbarBottom: Function,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface ControllerNavbarBottom{
    movementsPerDayRangePicker: boolean,
    movementsTicketsRangePicker: boolean,
    searchTicket: boolean,
    showTickets: boolean,
    transactionsPerDayRangePicker: boolean, 
    transactionsRangePicker: boolean,
    transactions: boolean,
    showTransactions: boolean
  }


  export type BodyMobile =  {
    idName: string
    nameButton: string,
    onClick: any,
    icon :  React.ReactNode,
    iconActive:  React.ReactNode,  
  }
  
  export const MobileContext = createContext<MobileContext>(new VoidContext());
  export const MobileContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [isVisibleState, setIsVisible] = useState(false);
    const [showBodyMobile, setShowBodyMobile] = useState<BodyMobile [] | undefined>();

    const [controllerNavbarBottom, setControllerNavbarBottom] = useState<ControllerNavbarBottom>({
      movementsPerDayRangePicker: false,
      movementsTicketsRangePicker: false,
      searchTicket: false,
      showTickets: false,
      transactionsPerDayRangePicker: false,
      transactionsRangePicker: false,
      transactions: false,
      showTransactions: false
    })

    return (
      <MobileContext.Provider value={{
        isVisibleState, 
        setIsVisible,
        showBodyMobile, 
        setShowBodyMobile,
        controllerNavbarBottom, 
        setControllerNavbarBottom,
      }}>{children}</MobileContext.Provider>
    );
  };



  